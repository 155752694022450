<template>
  <div>
    <error-box
      v-if="error"
      :loading="loading" />

    <div
      v-else-if="loading"
      v-loading="loading"
      class="page-loader" />

    <portal v-if="trade" to="actions">
      <el-button
        size="small"
        type="success"
        icon="el-icon-printer"
        round
        @click="print()">
        Print
      </el-button>
    </portal>

    <panel v-if="trade" :title="trade.tradeNumber">
      <data-list-item :item="{ label: 'Status' }">
        <div>
          <el-tag
            :type="statusColorClasses[trade.quoteStatus]"
            effect="dark"
            size="mini"
            class="right">
            {{ trade.quoteStatus | quoteStatus }}
          </el-tag>
        </div>
      </data-list-item>

      <data-list :list="dataList" />
    </panel>

    <div v-else>
      <empty v-if="!loading && !error" :icon="emptyIcon">You don't have any accounts.</empty>
    </div>
  </div>
</template>

<script>
import AccountsIcon from '@/assets/images/icons/accounts.svg'

import Empty from '@/components/common/ui/Empty'
import ErrorBox from '@/components/common/ui/ErrorBox'
import DataListItem from '@/components/common/ui/DataListItem'

export default {
  components: {
    Empty,
    ErrorBox,
    DataListItem
  },

  data () {
    return {
      error: null,
      loading: false,

      emptyIcon: AccountsIcon,
      statusColorClasses: window.quoteStatusColors
    }
  },

  computed: {
    user () {
      return this.$store.state.app.user
    },

    registration () {
      return this.user?.registration || null
    },

    id () {
      return this.$route.params.id
    },

    trade () {
      return this.$store.getters['quotes/get'](this.id)
    },

    systemFeatures () {
      return this.$store.state.app.systemFeatures
    },

    dataList () {
      if (!this.trade) return

      return [
        {
          label: 'Account',
          text: `****${this.trade.accountId.substr(4, 3)}`
        },
        {
          label: 'Profile',
          text: this.trade.profileId
        },
        {
          label: 'Created',
          text: this.$date(this.trade.createdAt)
        },
        {
          label: 'Completed By',
          text: this.trade.completedBy
        },
        {
          subtitle: 'Details',
          spacer: true
        },
        {
          label: 'Quote',
          text: this.trade.quoteNumber
        },
        {
          label: 'Trade',
          text: this.trade.tradeNumber
        },
        {
          label: 'Trade Type',
          text: this.trade.quoteType
        },
        {
          label: 'Aseet',
          text: this.trade.assetName
        },
        {
          label: 'Base Amount',
          text: this.$money(this.trade.baseAmount)
        },
        {
          label: 'Broker Fee',
          text: this.$money(Math.abs(this.trade.feeAmount))
        },
        {
          label: 'Total Amount',
          text: this.$money(this.trade.id)
        },
        {
          label: 'Price Per unit',
          text: parseFloat(this.trade.pricePerUnit)
        },
        {
          label: 'Unit Count',
          text: parseFloat(this.trade.unitCount)
        },
        {
          label: 'Trade Fee',
          text: this.$money(Math.abs(this.trade.tradeFee))
        },
        {
          label: 'Completed On',
          text: this.trade.trade?.providerSettledAt ? this.$date(this.trade.trade?.providerSettledAt) : null
        }
      ]
    }
  },

  created () {
    if (!this.systemFeatures['Trade Desk']) {
      this.$router.push({ name: 'Dashboard' })
      return
    }

    this.setPageTitle()
    this.load()
  },

  methods: {
    async load () {
      this.loading = true
      try {
        await this.$store.dispatch('quotes/get', this.id)
        this.setPageTitle()
      } catch (error) {
        console.error(error)
        this.error = true
      } finally {
        this.loading = false
      }
    },

    async print () {
      const loader = this.$loading()
      try {
        await this.$store.dispatch('quotes/action', {
          id: this.id,
          action: 'Print'
        })
        window.open(`${this.$server}/files/trades/${this.id}/${this.id}-trade.pdf`)
      } catch (e) {
        this.$notify.error(window.errorMessage)
      } finally {
        loader.close()
      }
    },

    setPageTitle () {
      this.$store.commit('page/setPageInfo', {
        title: this.trade ? `Trade - ${this.trade.tradeNumber}` : 'Trade',
        back: { name: 'TradeDesk' }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: $title;
    margin-bottom: 10px;
  }

  @media all and (max-width: 380px) {
    .flex-buttons::v-deep .el-button {
      text-align: center;
      svg {
        display: block;
        margin: 0 auto 5px auto;
      }
    }
  }
</style>
